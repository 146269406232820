import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';

export const CompareVehicleTrayMap = {
  compare_button_click: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'compare_cars', {
    action_cause: 'button_click',
  }),
  compare_cars_select: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'compare_cars_select', {
    action_cause: 'button_click',
  }),
  compare_tray_close: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, 'compare_tray_close'),
  compare_view_error: TrackingEvent.actionCompleted('view_error', 'compare_cars_error'),
};
