import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LinkWidget } from 'site-modules/shared/components/link-widget/link-widget';
import { getMonthYear } from 'client/site-modules/shared/utils/date-utils';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { LINKS_MAKE_DATA_RAW, MAKE_DATA_MAP } from './static-lease-link-widget-data';

import './static-lease-link-widget.scss';

function createWidgetDataFromMake(make, monthYear) {
  const makeSlug = makeNiceName(make);

  return {
    anchorText: `${make} lease deals & specials`,
    contextualSentence: `Find great lease deals on ${make} in ${monthYear}.`,
    href: `/${makeSlug}-lease-deals/`,
  };
}

export const StaticLeaseLinkWidget = memo(({ title, pageName, className, titleClassName }) => {
  const monthYear = getMonthYear();

  const makesData = MAKE_DATA_MAP[pageName] || LINKS_MAKE_DATA_RAW;

  const widgetData = {
    links: makesData.map(linkRaw => createWidgetDataFromMake(linkRaw, monthYear)),
  };

  const linkWidgets = [widgetData];

  return (
    <LinkWidget
      linkWidgets={linkWidgets}
      titleClassName={titleClassName}
      title={title}
      className={classnames('static-lease-link-widget', className)}
      showContextualSentence
      showSectionTitle={false}
    />
  );
});

StaticLeaseLinkWidget.displayName = 'StaticLeaseLinkWidget';

StaticLeaseLinkWidget.propTypes = {
  title: PropTypes.string,
  pageName: PropTypes.string,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
};

StaticLeaseLinkWidget.defaultProps = {
  title: 'Popular Lease Programs',
  pageName: '',
  className: '',
  titleClassName: 'heading-3',
};
