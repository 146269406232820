import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LinkWidgetsEntities } from 'client/data/models/link-widgets';
import { LinkWidgetSectionList } from 'site-modules/shared/components/link-widget-section-list/link-widget-section-list';

import './link-widget.scss';

/**
 * Links widget.
 * @param {Object} linkWidgets
 * @param {String} [title='related vehicle information']
 * @param {Boolean} [showSectionTitle=true]
 * @param {Boolean} [showContextualSentence=false]
 * @param {String} className
 * @param {String} titleClassName
 * @param {Boolean} divideSections
 * @return {ReactElement}
 */
export function LinkWidget({
  linkWidgets,
  title,
  showSectionTitle,
  showContextualSentence,
  className,
  titleClassName,
  divideSections,
}) {
  let sections = [].concat(linkWidgets);

  sections = sections.filter(val => !!val);

  if (!sections.length) return null;

  return (
    <section className={classnames('vdp-link-widget link-widget', className)} data-tracking-parent="edm-link-widget">
      <h2 className={titleClassName}>{title}</h2>
      <div className="links-lists">
        {sections.map((section, index) => {
          const key = `links-list-${index}`;
          return (
            <LinkWidgetSectionList
              widgetData={section}
              key={section.categoryName || section.header || key}
              showTitle={showSectionTitle}
              showSentence={showContextualSentence}
              linksNumToShow={section.linksNumToShow}
              multicolHeading={divideSections}
            />
          );
        })}
      </div>
    </section>
  );
}

LinkWidget.propTypes = {
  linkWidgets: LinkWidgetsEntities.LinkWidgets,
  title: PropTypes.string,
  showSectionTitle: PropTypes.bool,
  showContextualSentence: PropTypes.bool,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  divideSections: PropTypes.bool,
};

LinkWidget.defaultProps = {
  linkWidgets: [],
  title: 'Related vehicle information',
  showSectionTitle: true,
  showContextualSentence: false,
  className: 'pb-3',
  titleClassName: 'heading-3 mb-1_5',
  divideSections: true,
};
