import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { AnimatedCollapse } from 'client/site-modules/shared/utils/collapse-utils';
import { randomInt } from 'client/utils/seed-randomizers';

import './accordion.scss';

export class Accordion extends Component {
  static propTypes = {
    allowMultipleOpen: PropTypes.bool,
    iconCollapsed: PropTypes.string,
    iconExpanded: PropTypes.string,
    children: PropTypes.instanceOf(Object).isRequired,
    className: PropTypes.string,
    tabClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    chevronClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    headerClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    itemClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    headingLabelComponent: PropTypes.string,
    onExpand: PropTypes.func,
    tagsConfig: PropTypes.shape({
      accordionTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
      fragmentTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
      headingTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
      labelTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
      contentTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
    }),
  };

  static defaultProps = {
    allowMultipleOpen: false,
    iconCollapsed: 'icon-arrow-right4',
    iconExpanded: 'icon-arrow-down4',
    className: null,
    tabClasses: 'py-0_5',
    chevronClasses: 'size-10 text-primary-darker mr-0_75 px-0',
    headerClasses: '',
    itemClasses: 'ml-1_5 pl-0_25',
    headingLabelComponent: 'span',
    onExpand: null,
    tagsConfig: {
      accordionTag: 'dl',
      fragmentTag: Fragment,
      headingTag: Fragment,
      labelTag: 'dt',
      contentTag: 'dd',
    },
  };

  constructor(props) {
    super(props);
    const { children } = this.props;

    const openSections = {};

    children.forEach(child => {
      openSections[child.props.id] = child.props.isOpen;
    });

    this.state = { openSections };
  }

  onExpandChild = id => () => {
    const { openSections } = this.state;
    const { onExpand } = this.props;
    if (onExpand && openSections[id]) {
      onExpand(id);
    }
  };

  toggle = event => {
    const id = event.currentTarget.dataset.name;
    if (this.props.allowMultipleOpen) {
      this.setState(
        prevState => ({
          openSections: {
            ...prevState.openSections,
            [id]: !prevState.openSections[id],
          },
        }),
        this.onExpandChild(id)
      );
    } else {
      this.setState(
        prevState => ({
          openSections: {
            [id]: !prevState.openSections[id],
          },
        }),
        this.onExpandChild(id)
      );
    }
  };

  render() {
    const { openSections } = this.state;
    const {
      iconCollapsed,
      iconExpanded,
      children,
      className,
      tabClasses,
      chevronClasses,
      headerClasses,
      itemClasses,
      headingLabelComponent,
      tagsConfig: {
        accordionTag: AccordionTag,
        fragmentTag: FragmentTag,
        labelTag: LabelTag,
        headingTag: HeadingTag,
        contentTag: ContentTag,
      },
    } = this.props;

    const HeadingLabel = headingLabelComponent;

    return (
      <AccordionTag className={classnames('accordion', className)}>
        {children.map(child => {
          const componentId = randomInt();
          return (
            <FragmentTag key={child.props.id}>
              <LabelTag>
                <HeadingTag>
                  <button
                    data-name={child.props.id}
                    className={classnames('accordion-btn d-flex w-100', tabClasses)}
                    onClick={this.toggle}
                    data-tracking-id={child.props.trackingId}
                    aria-expanded={!!openSections[child.props.id]}
                    aria-controls={componentId}
                  >
                    <i
                      className={classnames('chevron', chevronClasses, {
                        [iconCollapsed]: !openSections[child.props.id],
                        [iconExpanded]: openSections[child.props.id],
                      })}
                      aria-hidden
                    />
                    <HeadingLabel
                      className={classnames(
                        'heading-label text-gray-darker size-16 text-left font-weight-normal m-0 p-0',
                        headerClasses,
                        {
                          'font-weight-bold': !!openSections[child.props.id],
                        }
                      )}
                    >
                      {child.props.label}
                    </HeadingLabel>
                  </button>
                </HeadingTag>
              </LabelTag>
              <AnimatedCollapse
                isOpen={!!openSections[child.props.id]}
                elementType={ContentTag}
                className="mb-0"
                id={componentId}
              >
                <div className={classnames('my-0_5 size-16', itemClasses)}>{child.props.children}</div>
              </AnimatedCollapse>
            </FragmentTag>
          );
        })}
      </AccordionTag>
    );
  }
}
