export const LINKS_MAKE_DATA_RAW = [
  'Ford',
  'Chevrolet',
  'Ram',
  'Subaru',
  'Jeep',
  'GMC',
  'Honda',
  'BMW',
  'Volvo',
  'Cadillac',
];

export const MAKE_DATA_MAP = {
  'srp-cheap-lease-deals': [...LINKS_MAKE_DATA_RAW, 'Audi', 'Hyundai', 'Lincoln', 'Toyota', 'Kia'],
};
